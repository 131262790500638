<template>
  <div class="main-content">
    <div class="register">
      <div class="top_line"></div>
      <div class="register_main">
        <img src="@/static/images/newlogo-150.jpg" style="width:350px" />
<!--        <div class="title">验证邮件</div>-->
        <a-result
            :status="status"
            :title="resulttext"
        >
        </a-result>
        <div>
          <a-button block type="danger"  @click="goindex">
            打开首页
          </a-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Config from "../config";

export default {
  data() {
    return {
      server_url: Config.data.server_url,
      loading: false,
      resulttext:"",
      status: "",
    }
  },
  created() {
    // 获取头部菜单内容
    console.log(this.$route.query.code)
    this.getstatus(this.$route.query.code)
  },

  methods: {

    agreeonChange(e) {
      console.log(`checked = ${e.target.checked}`);
      this.isagree = e.target.checked
    },
    async goindex() {
      this.$router.push({path: "/"});
    },
    async getstatus(code) {
      let result = await this.$post("/api/customer/checkEmail", {
          code: code,
      });
      console.log(`status`,result);
      if (result.status === true) {
            this.resulttext="邮件地址验证成功!";
            this.status="success";
      }

      this.loading = false;
    },

  }
}
</script>
<style lang="scss" scoped>
.main-content {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-image: url('../static/images/reg_bg1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.register {
  width: 600px;
  height: auto;
  margin-left: auto;
  margin-right: auto;

  .register_main {
    padding: 10px 20px 30px 20px;
    background: #fff;
    border-radius: 0px 0px 8px 8px;
    text-align: left;
  }

  .top_line {
    height: 8px;
    background: #002654;
    border-radius: 8px 8px 0px 0px;
  }

  .title {
    font-size: 16px;
    //font-weight: bold;
    color: #000000;
    line-height: 45px;
    margin-bottom: 40px;
    text-align: center;
  }
  .input{
    background: #F7F9FA;
    border-radius: 24px 24px 24px 24px;
    height: 44px;
    opacity: 1;
    border: 1px solid #E6EAEB;
    margin-bottom: 24px;
  }
}
.logins{
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
}
/deep/ .ant-btn-danger {
  height: 44px;
  margin-top: 24px;
  border-radius : 22px;
  color: #fff;
  background-color: #CE1126 ;
  border-color: #CE1126 ;
}
/deep/ .ant-btn-danger[disabled] {
  height: 44px;
  margin-top: 24px;
  border-radius : 22px;
  color: #222;
  background-color: #f5f5f5 ;
  border-color: #d9d9d9 ;
}
</style>
